import React, {useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonRow,
    IonThumbnail
} from "@ionic/react";
import {useSelector} from "react-redux";

type Props = {
    animationRef: any
    message: string
    location: string
}

const SlideshowDetailPage: React.FC<Props> = ({animationRef, message, location}) => {
    const currencyFormat = (num: Number) => {
        return '$' + num.toFixed(0) //.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const activeStateItem = useSelector((state: any) => state.slideshowItems.activeItem);
    const activeAuctionItems = useSelector((state: any) => state.slideshowItems.activeAuctionItems);

    const getHighBidderText = (item) => {
        // console.log(item)

        // Hiding for now due to lack of time AUC-906

        // const highbidder = item?.highbidder
        // if (highbidder && highbidder?.length > 0) {
        //     return item?.quantityavailable > 0 ? "High Bidder: " : "Winning Bidder: "  + highbidder
        // }
        // else {
        //     return "No High Bidder"
        // }
        return ""
    }

    return (
     <>
         <IonCard ref={animationRef} style={{height: '90%'}}>
             <IonCardHeader class="ion-text-center">
                 <IonCardTitle style={{color: 'black', fontSize: 45}}>
                     {location ? location + ' items' : 'All Items'}
                 </IonCardTitle>
             </IonCardHeader>
             <IonCardContent>
                 <IonGrid>
                     <IonRow>
                     {activeAuctionItems?.map((item) => {
                        return (
                            <>
                                <IonCol size={'5.75'} offset={'.25'} style={{borderBottom: '.5px solid gray'}}>
                                    <IonRow>
                                        <IonCol sizeXs={'12'} sizeSm={'12'} sizeMd={'6'} sizeLg={'4'} sizeXl={'3'}>
                                            <IonThumbnail >
                                                <img className="card-image" src={item?.imageurl} />
                                            </IonThumbnail>
                                        </IonCol>
                                        <IonCol sizeXs={'12'} sizeSm={'12'} sizeMd={'6'} sizeLg={'8'} sizeXl={'9'}>
                                            <IonRow>
                                                <IonCardTitle class="ion-padding-bottom" style={{color: 'black', fontSize: 18}}>
                                                    {item?.name}
                                                </IonCardTitle>
                                            </IonRow>
                                            <IonRow>
                                                <IonCardTitle style={{color: 'black', fontSize: 32}}>
                                                    {item?.quantityavailable > 0 ? currencyFormat(Number(item?.lastbid)) : 'SOLD OUT'}
                                                </IonCardTitle>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size={'6'} class="ion-text-left">
                                                    <IonCardTitle style={{color: 'gray', fontSize: 14}}>
                                                        {item?.buyitnowprice > 0 && currencyFormat(Number(item?.buyitnowprice)) + ' Buy It Now'}
                                                    </IonCardTitle>
                                                </IonCol>
                                                <IonCol size={'6'} class="ion-text-left">
                                                    {Number(item?.numberofbids) > 0 &&
                                                        <>
                                                            <IonCardTitle style={{color: 'gray', fontSize: 14}}>{item?.numberofbids} Bids</IonCardTitle>
                                                        </>
                                                     }
                                                    {Number(item?.quantityavailable) > 0 &&
                                                        <>
                                                            <IonCardTitle style={{color: 'gray', fontSize: 14}}>{item?.quantityavailable} Available</IonCardTitle>
                                                        </>
                                                    }
                                                        <>
                                                            <IonCardTitle style={{color: 'gray', fontSize: 14}}>{message}</IonCardTitle>
                                                        </>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                {/*{Number(item?.quantityavailable) > 0 &&*/}
                                                    <>
                                                        <IonCardTitle style={{color: 'gray', fontSize: 16}}>{getHighBidderText(item)}</IonCardTitle>
                                                    </>
                                                {/*}*/}
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </IonCol>
                            </>
                        )
                     })}
                     </IonRow>

                     {/*    <IonCol size={'6'}>*/}
                     {/*        <IonRow style={{borderBottom: '.5px solid gray'}}>*/}
                     {/*            <IonCol size={'2'}>*/}
                     {/*                <IonThumbnail>*/}
                     {/*                    <img className="card-image" src={activeStateItem?.imageurl} />*/}
                     {/*                </IonThumbnail>*/}
                     {/*            </IonCol>*/}
                     {/*            <IonCol size={'4'}>*/}
                     {/*                <IonRow>*/}
                     {/*                    <IonCardTitle style={{color: 'black', fontSize: 18}}>*/}
                     {/*                        {activeStateItem?.name}*/}
                     {/*                    </IonCardTitle>*/}
                     {/*                </IonRow>*/}
                     {/*                <IonRow>*/}
                     {/*                    <IonCardTitle style={{color: 'black', fontSize: 32}}>*/}
                     {/*                        {currencyFormat(Number(activeStateItem?.lastbid))}*/}
                     {/*                    </IonCardTitle>*/}
                     {/*                </IonRow>*/}
                     {/*                /!*<IonRow>*!/*/}
                     {/*                /!*  <IonCardTitle style={{color: 'gray', fontSize: 14, marginTop: 5, marginLeft: 30}}>*!/*/}
                     {/*                /!*    {currencyFormat(Number(slideShowRec?.buyitnowprice))} Buy It Now*!/*/}
                     {/*                /!*  </IonCardTitle>*!/*/}
                     {/*                /!*</IonRow>*!/*/}
                     {/*            </IonCol>*/}
                     {/*        </IonRow>*/}
                     {/*    </IonCol>*/}


                     {/*<IonRow>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*</IonRow>*/}
                     {/*<IonRow>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*</IonRow>*/}
                     {/*<IonRow>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*  <IonCol size={'6'}>*/}
                     {/*    <IonRow style={{marginRight: '20px', borderBottom: '.5px solid gray'}}>*/}
                     {/*      <IonThumbnail>*/}
                     {/*        <img className="card-image" src={slideShowRec?.imageurl} />*/}
                     {/*      </IonThumbnail>*/}
                     {/*    </IonRow>*/}
                     {/*  </IonCol>*/}
                     {/*</IonRow>*/}
                 </IonGrid>
             </IonCardContent>
         </IonCard>
     </>
 )
}
export default SlideshowDetailPage