import React, {useEffect} from "react"
import {useNavigate, useParams} from "react-router-dom"
import './index.css';
import {
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet,
  IonTabs,
  IonHeader, IonToolbar, IonTitle, IonButton, IonButtons, IonCol, IonRow, IonItem, IonImg, IonFooter
} from "@ionic/react";
import styled from "@emotion/styled";
import axios from "axios";
import RegisterPage from "./RegisterPage";
import WelcomePage from "./WelcomePage";
import {getConfig} from "../../AppConfig";


interface Params {
  eventcode: string
  id: string
}

const HeaderWrapper = styled.div`
  flex-grow: 100;
  display: flex;
  border: 1px solid #66666660;
  justify-content: center;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #66666660;
  height: 100px;
`;

const Store: React.FC = () => {
  const params = useParams();
  const eventcode = params.eventcode
  const eventtype = params.id

  const { apiUrl } = getConfig()
  const domainName =  window.location.hostname
  const domainNames = domainName.split('.')
  const auctioncode = (domainNames && domainNames.length > 0) ? domainNames[0] : ''
  // const navigate = useNavigate()
  var [storeData, setStoreData] = React.useState({
    storewelcomeeventname: '',
    storewelcomeheader: '',
    storewelcomeeventlogourl: '',
    storewelcomebuttonevent: '',
    storewelcomebuttonauction: '',
    storewelcomebuttoneventshow: true,
    storewelcomebuttonauctionshow: true,
    torewelcomefooter: '',
    storewelcomeauctionlink: '',
    tabs: [],
    token: '',
    hascheckedout: false,
    hasMerch: false,
  })
  const [selectedTab, setSelectedTab] = React.useState('')
  const [register, setRegister] = React.useState(false)
  // console.log(eventcode)
  // console.log(auctioncode)

  useEffect(() => {
    getStoreSetup()
  }, [])

  const getStoreSetup = async () => {
    setStoreData({
      storewelcomeeventname: '',
      storewelcomeheader: '',
      storewelcomeeventlogourl: '',
      storewelcomebuttonevent: '',
      storewelcomebuttonauction: '',
      storewelcomebuttoneventshow: false,
      storewelcomebuttonauctionshow: false,
      torewelcomefooter: '',
      storewelcomeauctionlink: '',
      tabs: [],
      token: '',
      hascheckedout: false,
      hasMerch: false,
    })

    const body = {auctioncode: auctioncode, eventcode: eventcode}
    const token = localStorage.getItem('tokenstore')

    const headers = token  ? {'Authorization': `Bearer ${token}`} : {}
    let url = `${apiUrl}/storesetup`
    const {data} = await axios.post(url, body, {headers: headers})
    var parsedJson = JSON.parse(data?.jsonInfo)
    parsedJson.hasMerch = false


    parsedJson?.tabs?.map(tab => {
      if (tab.tabname == 'Merchandise') {
          if (tab?.sections?.length > 0) {
              tab.sections.map(section => {
                  if (section?.data?.length > 0) {
                      const oneitme = section.data.find(item => item?.id > 0)
                      if (oneitme?.id > 0) {
                        parsedJson.hasMerch = true
                        tab.enabled = true
                      }
                      else {
                        tab.enabled = false
                      }
                  }
              })
          }
      }
    })

    setStoreData({...parsedJson})
    if (parsedJson.status === 'expired') {
      localStorage.removeItem("tokenstore")
      // history.push(`/${eventcode}/event`);
    }
    else if (parsedJson.token) {
      localStorage.setItem('tokenstore', parsedJson.token)
    }
    if (parsedJson && parsedJson.tabs) {
      if (parsedJson.hascheckedout) {
        setSelectedTab('Cart')
      }
      else {
        setSelectedTab(parsedJson.tabs[0].tabname)
      }

    }
  }


  if (eventtype === 'register') {
    return (
        <>
          <RegisterPage storeData={storeData} setStoreData={setStoreData} selectedTab={selectedTab} setSelectedTab={setSelectedTab} eventcode={eventcode} auctioncode={auctioncode}/>
        </>
    )
  }
  else {
    return (
        <>
          <WelcomePage storeData={storeData} setRegister={setRegister} eventcode={eventcode} auctioncode={auctioncode} />
        </>
    )
  }

}

export default Store
