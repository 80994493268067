import React from "react";
import {
    IonButton,
    IonCol,
    IonContent, IonFooter,
    IonHeader,
    IonItem,
    IonLabel,
    IonRow,
    IonTabButton,
    IonToolbar
} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Text from "../CommonStyles/Text";
import {Input} from "../AuctionItems/styledComponents";
import Store from "./Index";
import Img from "../../components/Header/Img";
import styled from "@emotion/styled";
import {Link, useNavigate} from "react-router-dom";

const HeaderWrapper = styled.div`
  flex-grow: 100;
  display: flex;
  //border: 1px solid #66666660;
  justify-content: center;
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  //border: 1px solid #66666660;
  //height: 100px;
`;

const WelcomePage = ({storeData, setRegister, eventcode, auctioncode}) => {
    const navigate = useNavigate()

    return (
        <>
            <IonHeader>
                {storeData?.status === 'expired' &&
                    <IonRow>
                        <IonCol style={{ margin: '20px', display: "flex-center", alignItems: 'center', justifyContent: 'center' }}>
                            <Text textAlign="center">
                                {storeData?.message}
                            </Text>
                        </IonCol>
                    </IonRow>
                }
                {storeData?.status === 'success' &&
                    <>
                        <IonRow>
                            <IonCol>
                                <HeaderWrapper>
                                    <HeaderContainer>
                                        <Img
                                            alt=""
                                            id="eventlogo"
                                            src={storeData?.storewelcomeeventlogourl}
                                            width={'100%'}
                                            height={'100%'}
                                            object-fit={'contain'}
                                        />
                                    </HeaderContainer>
                                </HeaderWrapper>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol style={{ margin: '20px', display: "flex-center", alignItems: 'center', justifyContent: 'center' }}>
                                <Text textAlign="center">
                                    <div dangerouslySetInnerHTML={{ __html: storeData?.storewelcomeheader }} />
                                </Text>
                            </IonCol>
                        </IonRow>
                    </>
                }

            </IonHeader>

            {storeData?.storewelcomebuttoneventshow &&
                <IonButton onClick={() => { navigate('/' + eventcode + '/event/register')}} style={{marginLeft: '40px', marginRight: '40px', marginBottom: '40px'}} expand="block" color={'primary'}>
                    {storeData?.storewelcomebuttonevent}
                </IonButton>
            }
            {storeData?.storewelcomebuttonauctionshow &&
                <IonButton href={storeData?.storewelcomeauctionlink}  id="notification-button" style={{marginLeft: '40px', marginRight: '40px'}} expand="block" color={'primary'}>
                    {storeData?.storewelcomebuttonauction}
                </IonButton>
            }
            <IonFooter>
                <Text textAlign="center">
                    <div dangerouslySetInnerHTML={{ __html: storeData?.torewelcomefooter }} />
                </Text>
                <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
                    <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                        EventSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                        <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                  </span>
                    </div>
                </div>
            </IonFooter>
        </>
    )
}

export default WelcomePage